<template>
  <!-- <div>
      <button @click="printEditorHtml">print html</button>
      <button @click="insertTextHandler">insert text</button>
      <button @click="disableHandler">disable</button>
    </div> -->
  <div style="min-height: 100vh; display: flex; flex-direction: column">
    <!-- 工具栏 -->
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" v-if="!id" />
    <!-- 编辑器 -->
    <div style="flex: 1">
      <Editor :defaultConfig="editorConfig" v-model="html" style="min-height: 400px" @onChange="onChange" @onCreated="onCreated" />
    </div>
  </div>
  <!-- <textarea name="" v-model="html" id=""></textarea> -->
</template>

<script>
  import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

  export default {
    name: "MyEditor",
    components: { Editor, Toolbar },
    data() {
      return {
        id: "",
        editor: null,
        html: "<p>hello&nbsp;world</p>",
        toolbarConfig: {
          // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
          // excludeKeys: [ /* 隐藏哪些菜单 */ ],
        },
        editorConfig: {
          placeholder: "请输入内容...",
          // autoFocus: false,

          // 所有的菜单配置，都要在 MENU_CONF 属性下
          MENU_CONF: {},
        },
      };
    },
    methods: {
      onCreated(editor) {
        this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
      },
      onChange(editor) {
        console.log("onChange", editor.getHtml()); // onChange 时获取编辑器最新内容
      },
      insertTextHandler() {
        const editor = this.editor;
        if (editor == null) return;
        editor.insertText(" hello ");
      },
      printEditorHtml() {
        const editor = this.editor;
        if (editor == null) return;
        console.log(editor.getHtml());
      },
      disableHandler() {
        const editor = this.editor;
        if (editor == null) return;
        editor.disable();
      },
    },
    mounted() {
      //获取url参数
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      this.id = params.get("id");
      // 模拟 ajax 请求，异步渲染编辑器
      // 模拟 ajax 请求，异步渲染编辑器
      //   setTimeout(() => {
      //     this.html = "<p>Ajax 异步设置内容 HTML</p>";
      //   }, 1500);
    },
    beforeDestroy() {
      const editor = this.editor;
      if (editor == null) return;
      editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
    },
  };
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>

<style>
  body {
    margin: 0;
  }
  td {
    min-width: 200px !important;
  }
</style>
